import styles from './CmsBlogPosts.module.scss';
import BlogCard from '../../../BlogCard/BlogCard';
import { Button, Link, Pill } from '@wienenergiegithub/ui-next';
import React from 'react';

const CmsBlogPosts = ({
  items,
  link,
  categories,
  groupTheme,
  hasFixedImage,
}) => (
  <>
    {categories && categories.length > 0 && (
      <div className={styles.tags}>
        {categories &&
          categories.map(({ name, link: categoryLink }) => (
            <Pill
              as={Link}
              className={styles.tag}
              key={`pill-${link}`}
              to={categoryLink}
            >
              {name}
            </Pill>
          ))}
      </div>
    )}
    <div className={styles.container}>
      {items.map(item => (
        <BlogCard
          hasFixedImage={hasFixedImage}
          {...item}
          key={`${item.title}`}
        />
      ))}
    </div>
    {link?.url && (
      <div className={styles.buttonContainer}>
        <Button
          as={Link}
          theme={groupTheme === 'default' ? 'primary' : 'ghost'}
          to={link.url}
          variant="outline"
        >
          {link.title}
        </Button>
      </div>
    )}
  </>
);

export default CmsBlogPosts;
