import useTranslation from '@wienenergiegithub/modules-next/src/common/hooks/i18n';

export const dateFormatter = date => {
  try {
    return new Date(date).toLocaleDateString('de-AT', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });
  } catch (e) {
    return null;
  }
};

export const dateFormatterInclWeekday = date => {
  try {
    return new Date(date).toLocaleDateString('de-AT', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  } catch (e) {
    return null;
  }
};

export const dateFormatterGetDay = date => {
  try {
    return new Date(date).toLocaleDateString('de-AT', {
      day: 'numeric',
    });
  } catch (e) {
    return null;
  }
};

export const dateFormatterGetMonth = date => {
  try {
    return new Date(date).toLocaleDateString('de-AT', {
      month: 'short',
    });
  } catch (e) {
    return null;
  }
};

export const dateFormatterGetTime = date => {
  try {
    return new Intl.DateTimeFormat('de-DE', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(date));
  } catch (e) {
    return null;
  }
};

export const dateFormatterGetTimePeriod = (startDate, endDate) => {
  const { t } = useTranslation('general');
  return `${dateFormatterGetTime(startDate)} - ${dateFormatterGetTime(
    endDate,
  )} ${t('hrs')}`;
};
