import styles from './TimedModal.module.scss';
import React, { useEffect, useMemo } from 'react';
import {
  Button,
  Content,
  Heading,
  Image,
  Link,
  Modal,
} from '@wienenergiegithub/ui-next';
import { usePageData, useLocalStorage } from '@wienenergiegithub/components';
import { useDialogStore } from '@ariakit/react';
import classNames from 'classnames';
import { useCookieConsent } from '@wienenergiegithub/modules-next/components';

const STATES = {
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN',
  CLOSED: 'CLOSED',
};

const TimedModal = () => {
  const {
    timedModal: {
      title = 'Umfrage',
      active = false,
      storageId = 'we-onsite-study',
      timeout = 10,
      heading,
      content,
      link,
      image,
      blacklist,
    },
  } = usePageData();
  const [state, setState] = useLocalStorage(storageId);
  const cookieConsent = useCookieConsent();
  const dialog = useDialogStore();
  const isVisible = dialog.useState('open');
  const hasCookieConsent = useMemo(
    () => cookieConsent.services.length > 0,
    [cookieConsent.services.length],
  );

  useEffect(() => {
    let timeoutId;

    if (
      active &&
      hasCookieConsent &&
      state !== STATES.CLOSED &&
      !blacklist?.includes(window.location.pathname)
    ) {
      timeoutId = setTimeout(() => {
        dialog.show();
      }, timeout * 1000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [active, state, hasCookieConsent]);

  useEffect(() => {
    if (!isVisible && state !== STATES.CLOSED) {
      setState(STATES.HIDDEN);
    } else if (isVisible && state !== STATES.CLOSED) {
      setState(STATES.VISIBLE);
    }
  }, [state, isVisible]);

  const handleClick = () => {
    dialog.hide();
    setState(STATES.CLOSED);
  };

  if (!active) {
    return null;
  }

  return (
    <Modal
      contentClassName={styles.modalContent}
      dialog={dialog}
      header={title}
      onClose={handleClick}
      theme="secondary"
    >
      <div className={styles.container}>
        <div className={styles.col}>
          <Heading size="h3">{heading}</Heading>
          <Content withParser>{content}</Content>
          {link && (
            <div className={styles.buttonWrapper}>
              <Button
                as={Link}
                className={styles.button}
                isElevated
                onClick={handleClick}
                rel="noopener noreferrer"
                target={link?.target || '_blank'}
                to={link?.url}
              >
                {link?.title}
              </Button>
            </div>
          )}
        </div>
        <div className={classNames(styles.col, styles.center)}>
          {image && (
            <Image alt={image.alt} className={styles.image} src={image.src} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TimedModal;
