import styles from './default.module.scss';
import { CmsComponents } from '../components/CmsComponents/CmsComponents';
import HelpWidget from '../components/HelpWidget/HelpWidget';
import TimedModal from '../components/TimedModal/TimedModal';
import {
  BusinessJourneyOverlay,
  Container,
  Layout,
  useTracking,
  NewsletterToastNotification,
  useSessionStorage,
  usePageData,
  CmsSiteEndComponent,
} from '@wienenergiegithub/components';
import {
  CookieConsent,
  CookieConsentProvider,
  PiwikConsentReactor,
} from '@wienenergiegithub/modules-next/components';
import React, { Suspense, useRef, useEffect, lazy } from 'react';
import classNames from 'classnames';
import {
  Breadcrumbs,
  Heading,
  SingleBreadcrumbItem,
} from '@wienenergiegithub/ui-next';

const mapType = type => {
  const types = {
    search: lazy(() => import('../pages/Search/Search')),
    404: lazy(() => import('../pages/NotFound/NotFound')),
    blog: lazy(() => import('../pages/Blog/Blog')),
    'blog-single': lazy(() => import('../pages/BlogSingle/BlogSingle')),
  };
  return types[type] || 'div';
};

const Page = () => {
  const {
    generalData,
    type,
    search,
    title,
    layoutStructure,
    blocks,
    breadcrumbs,
  } = usePageData();

  const [conditionalBlock] = useSessionStorage('show-conditional');
  const skipContentRef = useRef(null);
  const track = useTracking();
  const organicHeaderBg = layoutStructure.background;
  const siteEndTitle = generalData?.['title-general'];
  const siteEndContent = generalData?.['text-general'];
  const siteEndExists = siteEndTitle && siteEndContent;
  const TypeMapped = mapType(type);
  const hasFullWidthHero =
    blocks?.findIndex(block => block.hero_type === 'full') === 0;
  const theme =
    organicHeaderBg === 'blue-full' ||
    type === 'help-overview' ||
    hasFullWidthHero
      ? 'contrast'
      : 'default';
  const pageFields =
    TypeMapped === 'div' ? {} : { theme, title, ...generalData, ...search };
  const breadcrumb = breadcrumbs?.[0];

  useEffect(() => {
    const { campaign_name: campaignName, campaign_type: campaignType } =
      generalData;
    if (!campaignType || !campaignName) {
      return;
    }

    track({
      event: 'campaignPageInfo',
      title: window.document.title,
      url: `${window.location.pathname}${window.location.search}`,
      campaignName,
      campaignType,
    });
  }, []);

  return (
    <CookieConsentProvider hideOverlay={generalData.hide_privacy_overlay}>
      <Layout
        organicHeaderBg={organicHeaderBg}
        skipContentRef={skipContentRef}
        theme={theme}
      >
        <CookieConsent />
        <PiwikConsentReactor services={[]} />
        <>
          <Container>
            <>
              {!!layoutStructure.showBreadCrumbs && !!breadcrumb && (
                <Breadcrumbs
                  className={styles.breadcrumbs}
                  id="main-breadcrumbs"
                  theme={theme === 'contrast' ? 'contrast' : 'secondary'}
                >
                  <SingleBreadcrumbItem to={breadcrumb.link}>
                    {breadcrumb.title}
                  </SingleBreadcrumbItem>
                </Breadcrumbs>
              )}
              {layoutStructure.showTitle && (
                <Heading className={styles.title} size="h1" theme={theme}>
                  {title}
                </Heading>
              )}
            </>
          </Container>
          <Suspense fallback={null}>
            <TypeMapped {...pageFields} blocks={blocks}>
              {blocks && (
                <div
                  className={classNames(styles.contentContainer, {
                    [styles.zebra]: layoutStructure.hasZebraBackground,
                    [styles.zebraReverse]: layoutStructure.zebraReverse,
                  })}
                  id="cms-content"
                >
                  {blocks.map((block, index) => {
                    if (
                      // hide conditional blocks
                      block.groupTheme === 'hide-block' &&
                      conditionalBlock !== 'show'
                    ) {
                      return null;
                    }

                    return (
                      <div
                        className={classNames(styles.row, {
                          [styles.noPadding]: block.meta.noPadding,
                          [styles.reducedPadding]: block.meta.reducedPadding,
                          [styles.zebraRow]: block.meta.zebra,
                          [styles.noZebraRow]: block.meta.noZebra,
                        })}
                        key={block.id}
                      >
                        <CmsComponents
                          // container-md for entire blog entry
                          blogTemplate={layoutStructure.blogTemplate}
                          data={block}
                          scrollIndex={index}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </TypeMapped>
          </Suspense>
        </>
        {siteEndExists && (
          <CmsSiteEndComponent content={siteEndContent} title={siteEndTitle} />
        )}
        <HelpWidget />
        <TimedModal />
        <NewsletterToastNotification />
        <BusinessJourneyOverlay storageName="items" />
      </Layout>
    </CookieConsentProvider>
  );
};

export default Page;
